import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hello there!</h1>
    <p>Welcome to the Friendly Neightborhood therapist.</p>
    <p>
      This site is currently under construction. If you need help immediately,
      please reach out to the National Suicide Prevention Hotline at
      1-800-273-8255, or if this is an emergency in the United States, call 911.
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
